<template>
    <div class="d-flex flex-column flex-lg-row flex-column-fluid">
        <div class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
            <div class="d-flex flex-center flex-column flex-lg-row-fluid">
                <div class="w-lg-500px p-10 w-100">
                    <div class="w-lg-500px p-10">
                        <router-view></router-view>
                    </div>
                </div>
            </div>
            <!--<div class="d-flex flex-center flex-wrap px-5">
                <div class="d-flex fw-semibold text-primary fs-base">
                    <a href="https://virtaragroup.com.tr" class="px-5" target="_blank">{{ $t('auth.quickLink.homepage') }}</a>
                    <a href="https://dokuman.virtaragroup.com.tr" class="px-5" target="_blank">{{ $t('auth.quickLink.document') }}</a>
                    <a href="https://virtaragroup.com.tr/iletisim" class="px-5" target="_blank">{{ $t('auth.quickLink.contact') }}</a>
                </div>
            </div>-->
        </div>
        <div class="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" style="background-image: url(/media/misc/auth-bg.png);">
            <div class="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
                <a href="https://virtaragroup.com.tr" class="mb-0 custom-logo" target="_blank">
                    <img alt="Logo" src="/logo/virtaragroup-logo-icon.png" class="h-60px h-lg-100px">
                </a>
                <img class="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20" src="/media/misc/auth-screens.png" alt="">
                <h1 class="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7">{{ $t('auth.information.title') }}</h1>
                <div class="d-none d-lg-block text-white fs-base text-center" v-html="$t('auth.information.description')"></div>
            </div>
        </div>
    </div>
</template>

<script>
import {Actions} from "@/store/enum/StoreEnums";

export default {
    name: "new-version-auth",
    components: {},
    mounted(){
        this.$store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    },
    unmounted() {
        this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    }
};
</script>

<style>
@media (min-width: 992px) {
    .custom-logo {
        margin-bottom: 10rem !important;
    }
}
@media (min-width: 1200px) {
    .custom-logo {
        margin-bottom: 12rem !important;
    }
}
</style>